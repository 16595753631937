
import { ValidationObserver } from 'vee-validate'
import { EGtmEvent } from '@/helpers/enums'

export default {
  components: { ValidationObserver },
  layout: 'empty',

  async asyncData({ app, $i18n, $auth, $api, query, error, redirect }) {
    if (query?.token) {
      try {
        const { data } = await $api.account.authorizeWithUrlToken(query.token)

        $auth.strategy.token.set(data.access_token)
        $auth.strategy.refreshToken.set(data.refresh_token)

        redirect(app.$link.dashboard())
      } catch (err) {
        error({
          statusCode: err.response.status,
          message: $i18n.t(err.response?.data.messages[0])
        })
      }
    }
  },

  data() {
    return {
      email: '',
      password: '',
      showPwd: false,
      accountBlocked: false,
      accountBlockedMessage: '',
      accountError: false,
      accountErrorMessage: '',
      accountWillBlock: false,
      accountAttemptFailsMessage: '',
      showAuth0Message: '',
      displayAuth0message: false
    }
  },

  head() {
    return {
      title: this.$t('msp.v2.meta.title.login')
    }
  },

  mounted() {
    const getExist = typeof this.$route.query.exist !== 'undefined'
    const getExistForClient = typeof this.$route.query.existForClient !== 'undefined'

    this.displayAuth0message = getExist || getExistForClient

    if (this.displayAuth0message) {
      this.auth0Messages()
    }
  },

  methods: {
    async login() {
      const isValid = await this.$refs.loginObserver.validate()

      if (isValid) {
        try {
          await this.$auth.loginWith('local', {
            data: {
              email: this.email,
              password: this.password
            }
          })
        } catch ({ response }) {
          if (response.status === 406) {
            this.displayAuth0message = true
            this.showAuth0Message = this.$t('msp.v2.login.auth0.exist.other')
          } else {
            const getErr = response?.data

            if (getErr?.failedAttempts === 4) {
              this.accountWillBlock = true
              this.accountAttemptFailsMessage = this.$t('api.authorize.account.will.block')
            } else {
              this.accountWillBlock = false
            }

            if (getErr?.blockedFor) {
              this.accountError = false
              this.accountBlocked = true
              this.accountBlockedMessage = this.$t('api.authorize.account.blocked', {
                blockedFor: getErr.blockedFor,
                failedAttempts: getErr.failedAttempts
              })
            } else {
              this.accountBlocked = false
              this.accountError = true
              this.accountErrorMessage = this.$t(getErr.messages?.[0])
            }

            await this.$pushEvent(EGtmEvent.HR_LOGIN_ERROR, { responseCode: response.status }, true)
          }
        }
      }
    },

    auth0Messages() {
      if (this.$useConfig(this.$EClientConfig.MSP_LOGIN).auth0) {
        const hasUser = this.$route.query.exist === 'true'
        const hasUserClient = this.$route.query.existForClient === 'true'

        if (!hasUser) {
          this.showAuth0Message = this.$t('msp.v2.login.auth0.not.exist')
        }

        if (!hasUserClient) {
          this.showAuth0Message = this.$t('msp.v2.login.auth0.not.for.client')
        }
      }
    }
  }
}
